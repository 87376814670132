import React, { useEffect } from "react";
import { useCookies } from 'react-cookie';
import { TypeAnimation } from "react-type-animation";

import "./WelcomeScreen.css";

const WelcomeScreen = () => {
  const [cookies, setCookie] = useCookies();

  useEffect(() => {
    setCookie('welcome', true, {maxAge: 60})
  }, [])

  return (
    <div className="welcome_screen flex_display">
      <TypeAnimation
        sequence={[
          1000,  
          "Hi, ",
          1800, 
          "My Name is Bhaven. ",
          1800,
          "And I am a Developer. ",
          1800,
          "Welcome to my world. ",
          5000,
        ]}
        wrapper="h1"
        speed={30}
        style={{ fontSize: "3.5em", display: "inline-block" }}
        repeat={0}
      />
    </div>
  );
};

export default WelcomeScreen;

import React, { useEffect, useState } from "react";
import { useCookies } from 'react-cookie';

import WelcomeScreen from "../../Components/WelcomeScreen/WelcomeScreen";

import "./Home.css";

const Home = () => {
  const [cookies] = useCookies();

  const [welcome, setWelcome] = useState(false);

  useEffect(() => {
    if(!cookies?.welcome) setWelcome(true)
    const welcomeScreen = setTimeout(() => {
      setWelcome(false);
    }, 17000);

    return () => {
      clearTimeout(welcomeScreen);
    };
  }, []);

  return (
    <div className="home">
      {welcome ? <WelcomeScreen /> : <></>}
      <div className="home_background flex_display">
        <h1>Welcome to my world</h1>
      </div>
    </div>
  );
};

export default Home;
